import React from 'react'
import './Order.css'
 

function Orders () {
    
}


export default Orders


    

